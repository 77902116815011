import './bootstrap';
import '../css/app.css';

import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import {createI18n} from 'vue-i18n';
import {messages} from '@/i18n.js';
import VueTippy from 'vue-tippy';
import VuePapaParse from 'vue-papa-parse';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';


const i18n = createI18n({
    legacy: false,
    locale: 'ru', // set locale
    fallbackLocale: 'ru',
    messages,
    warnHtmlMessage: false,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createSSRApp({ render: () => h(App, props) })
        // return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(
                VueTippy,
                // optional
                {
                    hideOnClick: true,
                    maxWidth: 350,
                    directive: 'tippy', // => v-tippy
                    component: 'tippy', // => <tippy/>
                    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
                    defaultProps: {
                        placement: 'top',
                        allowHTML: true,
                        touch: false,
                    }, // => Global default options * see all props
                },
            )
            .use(VuePapaParse)
            .use(ZiggyVue)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
